import * as React from "react"
import Layout from "../components/layout"
import Introduction from "../components/introduction"
import Services from "../components/services"
import Testimonial from "../components/testimonial"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const ServicesPage = ({data}) => {

  const seoInfo = data.datoCmsServicesPage
  return(
  <Layout>
  
    <Seo description={seoInfo.metaDescription} title={seoInfo.metaTitle} />
  
    <Introduction title="Our Services" subtitle={data.datoCmsServicesPage.ourServices} caption={data.datoCmsServicesPage.ourServicesSmallText} /> 
  
    <Services services={data.allDatoCmsService} />

    <Testimonial author={data.datoCmsServicesPage.testimonialName} text={data.datoCmsServicesPage.testimonialQuotation} />

  </Layout>)
}

export const query = graphql`
  query {
    datoCmsServicesPage{
      testimonialName
      testimonialQuotation
      ourServicesSmallText
      metaDescription
    metaTitle
    ourServices

    }
    allDatoCmsService(sort: {fields: order}) {
      nodes {
        name
        byline
        image {
          gatsbyImageData
        }
      }
    }
  }
`
export default ServicesPage
