import React from 'react'
import { Link } from "gatsby"
import Arrow from "./partials/arrow"  
import {GatsbyImage} from 'gatsby-plugin-image'
import _ from 'lodash'

const Services = ({services}) =>{
    const allServices = services.nodes.map((service, index) => {
        return ( 
        <Link to={`/services/${_.kebabCase(service.name)}`} className="link" key={service?.name}>
            <div className={"service--row flex p20 mp0 align-center m-wrap " + (index+1 !== services.nodes?.length ?  'bb1' : '')}>
                <div className="image-wrapper w-100 max-85 mr20 m-m0 m-mb20 m-mt20">
                    <div className="ratio-1-1 pos-rel">
                        <GatsbyImage image={service.image?.gatsbyImageData} alt="Service Icon" className="bg-image contain" /> 
                    </div>
                </div>
                <div className="title-area pl40 mp0 pr20 bl1 m-b0">
                    <p className="m0 h3">{service?.name}</p>
                    <div className="copy sainte text-light max-650">
                        {service?.byline}
                    </div>
                </div>
                <div className="read-more mla flex align-center m-mb20 m-ml0 m-mt20">Read More <Arrow /></div>
            </div>
        </Link>
        )
    });
    return(
        <div className="all--services p20" style={{backgroundColor:'#E1E3DC'}}>
             {allServices}
        </div>
    )
}
export default Services